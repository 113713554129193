import React from 'react'
import '../styles/partners.css'
import Img from 'gatsby-image'

const PartnerComp = props => {
  return (
    <div className="partners-sections">
      <span>
        {' '}
        <Img fixed={props.image} />{' '}
      </span>
      <p className="partner-text">{props.text}</p>
    </div>
  )
}

export default PartnerComp
