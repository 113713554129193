import React from 'react'
import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { useIntl } from 'gatsby-plugin-intl'

//COMPONENTS
import Header from '../components/header'
import Footer from '../components/footer'
import PartnerComp from '../components/partnerComp'

//STYLES
import '../styles/partners.css'

const Partners = ({ data }) => {
  const intl = useIntl()
  /* let md = data.allMarkdownRemark.edges[0].node.frontmatter */
  let partnersArray =
    data.allMarkdownRemark.edges[0].node.frontmatter.partnersArray

  let partners = partnersArray.map((partner, i) => (
    <PartnerComp
      key={i}
      image={partner.image.childImageSharp.fixed}
      text={partner.companyText}
    />
  ))

  return (
    <div id="partners">
      <BackgroundImage
        className="header-img"
        fluid={data.partnersImage.childImageSharp.fluid}
        alt="Partners header image"
      >
        <Header />
        <div className="selling-text">
          <h1>{intl.formatMessage({ id: 'partners.title' })}</h1>
        </div>
      </BackgroundImage>
      <main>
        <div className="work-smarter">
          <p className="blue-text">
            {intl.formatMessage({ id: 'partners.work-smarter' })}
          </p>
          <h2 className="section-title">
            {intl.formatMessage({ id: 'partners.partners' })}
          </h2>
          <p>{intl.formatMessage({ id: 'partners.partnersText' })}</p>
        </div>
        <div className="our-partners">
          <div className="partners">{partners}</div>
        </div>
      </main>
      <Footer />
    </div>
  )
}

export const query = graphql`
  {
    allMarkdownRemark(filter: { frontmatter: { key: { eq: "partners" } } }) {
      edges {
        node {
          frontmatter {
            title
            newRelic
            microsoft
            partnersText
            partners
            workSmarter
            aws
            partnersArray {
              company
              companyText
              image {
                childImageSharp {
                  fixed(height: 40) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
    partnersImage: file(relativePath: { eq: "partners-image.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default Partners
